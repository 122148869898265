.swiper-pagination-bullet {
  opacity: 1 !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
  @apply bg-gray-0
}


.swiper-button-prev:after, .swiper-button-next:after {
  width: 10px;
  height: 18px;
  top: 15px;
  left: 19px;
  content: "" !important;
}

.swiper-button-disabled:after {
  opacity: 0.6;
}

.swiper-button-next::after {
  background-image: url("/assets/arrow-right.svg");
}

.swiper-button-prev::after {
  background-image: url("/assets/arrow-left.svg");
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 48px !important;
  height: 48px !important;
  top: calc(50% - 48px) !important;
  transform: translate(0, -50%);
  margin: 0 !important;
  background-size: contain !important;

  background-color: rgba(0, 0, 0, 0.6) !important;
  background-repeat: no-repeat;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.18) !important;
  backdrop-filter: blur(4px) !important;
  border-radius: 14px;
}
